import React from 'react';
import useAnalyticsEventTracker from '../../ReactGA/useAnalyticsEventTracker';
import Section from '../../../HOC/Section';

// import img1 from '../../../assets/comunicadores/1.jpg';
// import img2 from '../../../assets/comunicadores/2.jpg';
// import img3 from '../../../assets/comunicadores/3.png';
// import img4 from '../../../assets/comunicadores/4.png';
import img5 from '../../../assets/comunicadores/5.png';
import img6 from '../../../assets/comunicadores/6.webp';


const Comunicadores = () => {
  const gaEventTracker = useAnalyticsEventTracker('Comunicadores');
  return (
    <Section id='comunicadores'>
      <div className='container pt-2 pb-5'>
        <div className='section-header pt-5 pb-5 text-center'>
          <h3 className='section-title'>
            <span>Comunicadores </span>de Arbitragem
          </h3>
          <h6 className='section-subtitle mr-auto ml-auto'>
          Explore o poder dos comunicadores inteligentes: soluções inovadoras que transformam interação em eficiência, conectando pessoas e ideias com inteligência e rapidez.
          </h6>
        </div>
        <div className='section-content'>
          <div className='row'>
           
            <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={img5} className='card-img-top' alt='Blog 3' />
                <div className='card-body'>
                  <h5 className='card-title'>Intercomunicador V4 Xinowy Arbitragem </h5>
                  <p className='card-text'>
                  V4 xinowy ideal para arbitragem, comunica 4 simultaneamente (ao mesmo tempo). Perfeito para grupo de 4 Árbitros. Árbitros podem se comunicar sem dificuldades na faixa de 1200m. Versão Bluetooth: 3.0
</p>
                 <a href='https://api.whatsapp.com/send?phone=5541920042554&text=%20Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto%20que%20vi%20no%20site'onClick={()=>gaEventTracker('moto')} className='btn btn-sm btn-primary' target="_blank" rel="noopener noreferrer">
                  Mais informações (whatsapp)
                  </a>
                </div>
              </div>
            </div>
            <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={img6} className='card-img-top' alt='Blog 3' />
                <div className='card-body'>
                  <h5 className='card-title'>Intercomunicador Ejeas V4 Plus Arbitragem </h5>
                  <p className='card-text'>
                  V4 Plus ideal para arbitragem, Fala 4 ao mesmo tempo. Ideal para grupo de 4 árbitros.
Distância: Até 1500 metros (4 conjuntos Conectados).
Versão do bluetooth 5.1
<br />
</p>
                 <a href='https://api.whatsapp.com/send?phone=5541920042554&text=%20Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto%20que%20vi%20no%20site'onClick={()=>gaEventTracker('moto')} className='btn btn-sm btn-primary' target="_blank" rel="noopener noreferrer">
                  Mais informações (whatsapp)
                  </a>
                </div>
              </div>
            </div>
           </div>
        </div>
      </div>
    </Section>
  );
};

export default Comunicadores;
