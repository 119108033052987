import React, { useState, useEffect } from 'react';

import Link from '../Link/Link';
import logo from '../../../assets/img/logo.png';
import "./Nav.scss";
const Nav = () => {
  const [navClass, setNavClass] = useState('');
  const [toggeledNav, settoggeledNav] = useState(false);

  const toggleNav = () => {
    settoggeledNav(!toggeledNav);
  };

  useEffect(() => {
    window.addEventListener('scroll', () => {
      let navClass = '';
      if (window.scrollY >= 200) {
        navClass = 'scrolled';
      }
      setNavClass(navClass);
    });
  }, []);
  return (
    <nav className={`navbar navbar-expand-md bg-light ${navClass}`}>
      <div className='container'>
        <a className='navbar-brand' href='/'>
          <img src={logo} width="118x" alt='logo' />
          {/* <i className='fas fa-circle ml-1' /> */}
        </a>
        <div
          className={`navbar-toggler nav-icon ${(() => {
            if (toggeledNav) return 'open';
            return '';
          })()}`}
          onClick={toggleNav}
        >
          <span />
          <span />
          <span />
        </div>

        <div
          className={`collapse navbar-collapse ${(() => {
            if (toggeledNav) return 'show';
            return '';
          })()}`}
        >
          <ul className='navbar-nav ml-auto'>
            <li className='nav-item'>
              <Link target='home' offset={-120} classes='nav-link'>
                Home
              </Link>
            </li>
            <li className='nav-item'>
              <Link target='about' classes='nav-link'>
                A Empresa
              </Link>
            </li>
            {/* <li className='nav-item'>
              <Link target='services' classes='nav-link'>
                Serviços
              </Link>
            </li> */}
            <li
              className="nav__menu-item"
            >
              <Link classes='nav-link' >Produtos</Link>
              <Submenu/>
            </li>
            {/* <li className='nav-item'>
              <Link target='blog' classes='nav-link'>
                Produtos
              </Link>
            </li> */}
            <li className='nav-item'>
              <Link target='contact' classes='nav-link'>
                Contato
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

class Submenu extends React.Component {
  render() {
    return (
      <ul className="nav__submenu">
        <li className="nav__submenu-item ">
        <li className="nav__submenu-item ">
          <Link target="leitores" classes='nav-link nav-sub-link' ><i class="fa fa-bars fa-lg"></i> Leitores</Link>
        </li>
          <Link target="comunicadores" classes='nav-link nav-sub-link' ><i class="fa fa-bars fa-lg"></i> Comunicadores</Link>
        </li>
        <li className="nav__submenu-item ">
          <Link target="casa" classes='nav-link nav-sub-link' ><i class="fa fa-bars fa-lg"></i> Casa Inteligente</Link>
        </li>
        <li className="nav__submenu-item ">
          <Link target="phone" classes='nav-link nav-link nav-sub-link'><i class="fa fa-bars fa-lg"></i> Smartphones Resistentes</Link>
        </li>
        <li className="nav__submenu-item ">
          <Link target="moto" classes='nav-link nav-link nav-sub-link '><i class="fa fa-bars fa-lg"></i>  Acessórios para motos</Link>
        </li>
        <li className="nav__submenu-item ">
          <Link target="inter"  classes='nav-link nav-link nav-sub-link'><i class="fa fa-bars fa-lg" aria-hidden="true"></i>  Intercomunicadores</Link>
        </li>
      </ul>
    )
  }
}
export default Nav;
