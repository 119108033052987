import React, { Fragment } from 'react';

import Home from './Home/Home';
import About from './About/About';
import Facts from './Facts/Facts';
import Comunicadores from './Blog/comunicadores';
import Leitores from './Blog/leitores';
import Casa from './Blog/Casa';
import Moto from './Blog/Moto';
import Inter from './Blog/Inter';
import Phone from './Blog/Phone';
import Contact from './Contact/Contact';
import MapMarker from './MapMarker/MapMarker';

const sections = () => {
  return (
    <Fragment>
      <Home />
      <About />
      <Leitores/>
      <Comunicadores />
      <Casa />
      <Moto />
      <Inter />
      <Phone />
      <Facts />
      <Contact />
      <MapMarker />
    </Fragment>
  );
};

export default sections;
