import React from 'react';

import Section from '../../../HOC/Section';
import aboutImage from '../../../assets/img/about.jpg';
import Link from '../../UI/Link/Link';
const about = () => {
  return (
    <Section id='about'>
      <div className='container pt-2 pb-5'>
        <div className='section-header pt-5 pb-5 text-center'>
          <h3 className='section-title'>
            <span>Sobre </span>Nós
          </h3>
          <h6 className='section-subtitle mr-auto ml-auto'>
          A Masters Tecnologia é uma empresa focada na comercialização de produtos eletrônicos e acessórios para facilitar o seu dia a dia.
          </h6>
        </div>
        <div className='section-content'>
          <div className='row'>
            <div className='col-md-12 col-lg-6 mb-3'>
              <div className='aboutImage'>
                <img src={aboutImage} alt='about company' />
              </div>
            </div>
            <div className='col-md-12 col-lg-6'>
              <h3 className='about-title'>Sobre Nós</h3>
              <div className='about-description'>
                <p>
                Possuímos uma gama de produtos que vão deixar a sua casa conectada com você, além de oferecer entrega para todo Brasil.
                
                </p>
                <Link target="leitores">
                <button className='btn btn-primary rounded-0'>Saiba mais sobre nosso produtos</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default about;
