import React from 'react';
import useAnalyticsEventTracker from '../../ReactGA/useAnalyticsEventTracker';
import Section from '../../../HOC/Section';

import img1 from '../../../assets/casa/1.jpg';
import img2 from '../../../assets/casa/2.jpg';
import img3 from '../../../assets/casa/3.jpg';
import img4 from '../../../assets/casa/4.jpg';
import img5 from '../../../assets/casa/5.jpg';
import img6 from '../../../assets/casa/6.jpg';
import img7 from '../../../assets/casa/7.jpg';
import img8 from '../../../assets/casa/8.jpg';
import img9 from '../../../assets/casa/9.jpg';
import img10 from '../../../assets/casa/10.jpg';
import img11 from '../../../assets/casa/11.jpg';
import img12 from '../../../assets/casa/12.jpg';
// import img13 from '../../../assets/casa/13.jpg';
// import img14 from '../../../assets/casa/14.jpg';
// import img15 from '../../../assets/casa/15.jpg';
import img16 from '../../../assets/casa/16.jpg';
import img17 from '../../../assets/casa/17.jpg';
// import img18 from '../../../assets/casa/18.jpg';
import img19 from '../../../assets/casa/19.jpg';
import img20 from '../../../assets/casa/20.jpg';
import img21 from '../../../assets/casa/21.jpg';
import img22 from '../../../assets/casa/22.jpg';
import img23 from '../../../assets/casa/23.jpg';
import img24 from '../../../assets/casa/24.jpg';
import img25 from '../../../assets/casa/25.jpg';
import img26 from '../../../assets/casa/26.jpg';
import img27 from '../../../assets/casa/27.jpg';
import img28 from '../../../assets/casa/28.jpg';
import img29 from '../../../assets/casa/29.png';

const Casa = () => {
  const gaEventTracker = useAnalyticsEventTracker('Casa Inteligente');
  return (
    <Section id='casa'>
      <div className='container pt-2 pb-5'>
        <div className='section-header pt-5 pb-5 text-center'>
          <h3 className='section-title'>
            <span>Casa </span>Inteligente
          </h3>
          <h6 className='section-subtitle mr-auto ml-auto'>
            Para deixar os ambientes da sua casa sempre conectados, disponibilizamos diversos produtos que integram entre si, criando cenários e rotinas inteligentes, tudo isso de uma forma simples e descomplicada.
          </h6>
        </div>
        <div className='section-content'>
          <div className='row'>
            <div className='col-lg-4 mb-3'>
            <div className='card rounded-0'>
                <img src={img29} className='card-img-top' alt='Blog 1' />
                <div className='card-body'>
                  <h5 className='card-title'>Capacitor Para Interruptor sem Neutro</h5>
                  <p className='card-text'>
                  Capacitor 4,5uF ou 3,3uF Para ligar interruptor sem Neutro <br /> <br /> <br />
                    <br />
                  </p>

                  <a href='https://api.whatsapp.com/send?phone=5541920042554&text=%20Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto%20que%20vi%20no%20site' onClick={() => gaEventTracker('casa')} className='btn btn-sm btn-primary' target="_blank" rel="noopener noreferrer">
                    Mais informações (whatsapp)
                  </a>
                </div>
              </div>
              </div>
              <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={img1} className='card-img-top' alt='Blog 1' />
                <div className='card-body'>
                  <h5 className='card-title'>Controle Infravermelho Universal WIFI</h5>
                  <p className='card-text'>
                    Feito para você controlar todos os seus dispositivos através deste único controle, pelo APP ou por comando de voz.
                    TVs, Ar Condicionados, Home Theathers e Tv Box e Outros
                    <br />
                  </p>

                  <a href='https://api.whatsapp.com/send?phone=5541920042554&text=%20Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto%20que%20vi%20no%20site' onClick={() => gaEventTracker('casa')} className='btn btn-sm btn-primary' target="_blank" rel="noopener noreferrer">
                    Mais informações (whatsapp)
                  </a>
                </div>
              </div>
            </div>
            <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={img2} className='card-img-top' alt='Blog 2' />
                <div className='card-body'>
                  <h5 className='card-title'>Interruptor Inteligente Touch 1 botão – TUYA – com e sem neutro</h5>
                  <p className='card-text'>
                    Modelo compatível com os assistentes de
                    voz Amazon Alexa e Google Home, além dos aplicativos Siri, Smart Life e Tuya Smart
                    <br /><br />
                  </p>

                  <a href='https://api.whatsapp.com/send?phone=5541920042554&text=%20Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto%20que%20vi%20no%20site' onClick={() => gaEventTracker('casa')} className='btn btn-sm btn-primary' target="_blank" rel="noopener noreferrer">
                    Mais informações (whatsapp)
                  </a>
                </div>
              </div>
            </div>
            <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={img3} className='card-img-top' alt='Blog 3' />
                <div className='card-body'>
                  <h5 className='card-title'>Interruptor Inteligente Touch 3 botões – TUYA - com e sem neutro</h5>
                  <p className='card-text'>
                    Modelo compatível com os assistentes de
                    voz Amazon Alexa e Google Home, além dos aplicativos Siri, Smart Life e Tuya Smart
                  </p>

                  <a href='
https://api.whatsapp.com/send?phone=5541920042554&text=%20Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto%20que%20vi%20no%20site' onClick={() => gaEventTracker('casa')} className='btn btn-sm btn-primary' target="_blank" rel="noopener noreferrer">
                    Mais informações (whatsapp)
                  </a>
                </div>
              </div>
            </div>
            <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={img4} className='card-img-top' alt='Blog 3' />
                <div className='card-body'>
                  <h5 className='card-title'>Interruptor Inteligente Touch 3 botões – TUYA - com e sem neutro</h5>
                  <p className='card-text'>
                    Modelo compatível com os assistentes de
                    voz Amazon Alexa e Google Home, além dos aplicativos Siri, Smart Life e Tuya Smart
                    <br /><br />
                  </p>

                  <a href='
https://api.whatsapp.com/send?phone=5541920042554&text=%20Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto%20que%20vi%20no%20site' onClick={() => gaEventTracker('casa')} className='btn btn-sm btn-primary' target="_blank" rel="noopener noreferrer">
                    Mais informações (whatsapp)
                  </a>
                </div>
              </div>
            </div>
            <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={img5} className='card-img-top' alt='Blog 3' />
                <div className='card-body'>
                  <h5 className='card-title'>Interruptor Inteligente Touch 6 botões – TUYA – com e sem neutro</h5>
                  <p className='card-text'>
                    Modelo compatível com os assistentes de
                    voz Amazon Alexa e Google Home, além dos aplicativos Siri, Smart Life e Tuya Smart – Consulte também a Caixa de Embutir própria.

                  </p>

                  <a href='
https://api.whatsapp.com/send?phone=5541920042554&text=%20Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto%20que%20vi%20no%20site' onClick={() => gaEventTracker('casa')} className='btn btn-sm btn-primary' target="_blank" rel="noopener noreferrer">
                    Mais informações (whatsapp)
                  </a>
                </div>
              </div>
            </div>
            <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={img6} className='card-img-top' alt='Blog 3' />
                <div className='card-body'>
                  <h5 className='card-title'>Interruptor de Lâmpada e Ventilador Touch Wifi</h5>
                  <p className='card-text'>
                    O interruptor permite o controle de lâmpadas e ventiladores através de comando de voz, compatível com Amazon Alexa, Google Home e aplicativo Siri, Smart Life, Tuya Smart, entre outros.
                    <br /><br />
                  </p>

                  <a href='
https://api.whatsapp.com/send?phone=5541920042554&text=%20Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto%20que%20vi%20no%20site' onClick={() => gaEventTracker('casa')} className='btn btn-sm btn-primary' target="_blank" rel="noopener noreferrer">
                    Mais informações (whatsapp)
                  </a>
                </div>
              </div>
            </div>
            <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={img7} className='card-img-top' alt='Blog 3' />
                <div className='card-body'>
                  <h5 className='card-title'>Interruptor de Cortina Inteligente Touch - Tuya</h5>
                  <p className='card-text'>
                    Controle para abertura e fechamento de cortinas, compatível com os assistentes de voz Amazon Alexa e Google Home, além dos aplicativos Siri, Smart Life e Tuya Smart</p>

                  <a href='
https://api.whatsapp.com/send?phone=5541920042554&text=%20Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto%20que%20vi%20no%20site' onClick={() => gaEventTracker('casa')} className='btn btn-sm btn-primary' target="_blank" rel="noopener noreferrer">
                    Mais informações (whatsapp)
                  </a>
                </div>
              </div>
            </div>
            <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={img8} className='card-img-top' alt='Blog 3' />
                <div className='card-body'>
                  <h5 className='card-title'>Interruptor Inteligente Touch 1 botão - Avatto</h5>
                  <p className='card-text'>
                    Modelo compatível com os assistentes de
                    voz Amazon Alexa e Google Home, além dos aplicativos Siri, Smart Life e Tuya Smart
                    <br /><br />
                  </p>

                  <a href='
https://api.whatsapp.com/send?phone=5541920042554&text=%20Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto%20que%20vi%20no%20site' onClick={() => gaEventTracker('casa')} className='btn btn-sm btn-primary' target="_blank" rel="noopener noreferrer">
                    Mais informações (whatsapp)
                  </a>
                </div>
              </div>
            </div>
            <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={img9} className='card-img-top' alt='Blog 3' />
                <div className='card-body'>
                  <h5 className='card-title'>Interruptor Inteligente Touch 2 botões - Avatto</h5>
                  <p className='card-text'>
                    Interruptor Inteligente Touch 2 botões - Avatto
                    Modelo compatível com os assistentes de
                    voz Amazon Alexa e Google Home, além dos aplicativos Siri, Smart Life e Tuya Smart
                  </p>

                  <a href='
https://api.whatsapp.com/send?phone=5541920042554&text=%20Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto%20que%20vi%20no%20site' onClick={() => gaEventTracker('casa')} className='btn btn-sm btn-primary' target="_blank" rel="noopener noreferrer">
                    Mais informações (whatsapp)
                  </a>
                </div>
              </div>
            </div>
            <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={img10} className='card-img-top' alt='Blog 3' />
                <div className='card-body'>
                  <h5 className='card-title'>Interruptor Inteligente Touch 3 botões - Avatto</h5>
                  <p className='card-text'>
                    Modelo compatível com os assistentes de
                    voz Amazon Alexa e Google Home, além dos aplicativos Siri, Smart Life e Tuya Smart
                  </p>

                  <a href='
https://api.whatsapp.com/send?phone=5541920042554&text=%20Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto%20que%20vi%20no%20site' onClick={() => gaEventTracker('casa')} className='btn btn-sm btn-primary' target="_blank" rel="noopener noreferrer">
                    Mais informações (whatsapp)
                  </a>
                </div>
              </div>
            </div>
            <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={img11} className='card-img-top' alt='Blog 3' />
                <div className='card-body'>
                  <h5 className='card-title'>Interruptor Inteligente Touch 4 botões 4x4 – Avatto </h5>
                  <p className='card-text'>
                    Modelo compatível com os assistentes de
                    voz Amazon Alexa e Google Home, além dos aplicativos Siri, Smart Life e Tuya Smart</p>

                  <a href='
https://api.whatsapp.com/send?phone=5541920042554&text=%20Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto%20que%20vi%20no%20site' onClick={() => gaEventTracker('casa')} className='btn btn-sm btn-primary' target="_blank" rel="noopener noreferrer">
                    Mais informações (whatsapp)
                  </a>
                </div>
              </div>
            </div>
            <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={img12} className='card-img-top' alt='Blog 3' />
                <div className='card-body'>
                  <h5 className='card-title'>Interruptor Inteligente Touch 6 botões 4x4 - Avatto</h5>
                  <p className='card-text'>
                    Modelo compatível com os assistentes de
                    voz Amazon Alexa e Google Home, além dos aplicativos Siri, Smart Life e Tuya Smart

                  </p>

                  <a href='
https://api.whatsapp.com/send?phone=5541920042554&text=%20Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto%20que%20vi%20no%20site' onClick={() => gaEventTracker('casa')} className='btn btn-sm btn-primary' target="_blank" rel="noopener noreferrer">
                    Mais informações (whatsapp)
                  </a>
                </div>
              </div>
            </div>
            {/* <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={img13} className='card-img-top' alt='Blog 3' />
                <div className='card-body'>
                  <h5 className='card-title'>Controle para automação de Iluminação - Sonoff Mini R2</h5>
                  <p className='card-text'>
                    O Sonoff DIY MINI é o novo modelo de automatizadores da itead Sonoff, sua configuração permite que instale um interruptor convencional para que consiga acionar sua iluminação tanto no interruptor fisicamente quando através do aplicativo "EweLink" utilizando sua rede Wi-Fi.</p>

                  <a href='
https://api.whatsapp.com/send?phone=5541920042554&text=%20Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto%20que%20vi%20no%20site' onClick={() => gaEventTracker('casa')} className='btn btn-sm btn-primary' target="_blank" rel="noopener noreferrer">
                    Mais informações (whatsapp)
                  </a>
                </div>
              </div>
            </div> */}
            {/* <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={img14} className='card-img-top' alt='Blog 3' />
                <div className='card-body'>
                  <h5 className='card-title'>Controle de iluminação para fita de led + controle remoto – Magic Home</h5>
                  <p className='card-text'>
                    Controle a intensidade da iluminação do led através dos assistentes de voz Amazon Alexa e Google Home, além dos aplicativos Siri, Smart Life e Tuya Smart
                    <br /><br /><br />
                  </p>

                  <a href='
https://api.whatsapp.com/send?phone=5541920042554&text=%20Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto%20que%20vi%20no%20site' onClick={() => gaEventTracker('casa')} className='btn btn-sm btn-primary' target="_blank" rel="noopener noreferrer">
                    Mais informações (whatsapp)
                  </a>
                </div>
              </div>
            </div> */}
            {/* <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={img15} className='card-img-top' alt='Blog 3' />
                <div className='card-body'>
                  <h5 className='card-title'>Controle de iluminação para fita de led – Magic Home</h5>
                  <p className='card-text'>
                    Controle a intensidade da iluminação do led através dos assistentes de voz Amazon Alexa e Google Home, além dos aplicativos Siri, Smart Life e Tuya Smart
                    <br /><br /><br /><br />
                  </p>

                  <a href='
https://api.whatsapp.com/send?phone=5541920042554&text=%20Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto%20que%20vi%20no%20site' onClick={() => gaEventTracker('casa')} className='btn btn-sm btn-primary' target="_blank" rel="noopener noreferrer">
                    Mais informações (whatsapp)
                  </a>
                </div>
              </div>
            </div> */}
            <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={img16} className='card-img-top' alt='Blog 3' />
                <div className='card-body'>
                  <h5 className='card-title'>Interruptor Inteligente Touch 1 botão e tomada - Smartlife</h5>
                  <p className='card-text'>
                    Modelo compatível com os assistentes de
                    voz Amazon Alexa e Google Home, além dos aplicativos Siri, Smart Life e Tuya Smart
                    <br /><br />
                  </p>

                  <a href='
https://api.whatsapp.com/send?phone=5541920042554&text=%20Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto%20que%20vi%20no%20site' onClick={() => gaEventTracker('casa')} className='btn btn-sm btn-primary' target="_blank" rel="noopener noreferrer">
                    Mais informações (whatsapp)
                  </a>
                </div>
              </div>
            </div>
            <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={img17} className='card-img-top' alt='Blog 3' />
                <div className='card-body'>
                  <h5 className='card-title'>Interruptor Inteligente Touch 2 botões e tomada - Smartlife</h5>
                  <p className='card-text'>
                    Modelo compatível com os assistentes de
                    voz Amazon Alexa e Google Home, além dos aplicativos Siri, Smart Life e Tuya Smart
                    <br /><br />
                  </p>

                  <a href='
https://api.whatsapp.com/send?phone=5541920042554&text=%20Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto%20que%20vi%20no%20site' onClick={() => gaEventTracker('casa')} className='btn btn-sm btn-primary' target="_blank" rel="noopener noreferrer">
                    Mais informações (whatsapp)
                  </a>
                </div>
              </div>
            </div>
            {/* <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={img18} className='card-img-top' alt='Blog 3' />
                <div className='card-body'>
                  <h5 className='card-title'>Saboneteira Automática Recarregável – Saop Dispenser</h5>
                  <p className='card-text'>
                    Dispenser automático, possui sensor infravermelho de movimento e 3 níveis de ajuste para quantidade de espuma. Aceita uso de sabonete líquido, loção corporal, xampu entre outros.</p>

                  <a href='
https://api.whatsapp.com/send?phone=5541920042554&text=%20Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto%20que%20vi%20no%20site' onClick={() => gaEventTracker('casa')} className='btn btn-sm btn-primary' target="_blank" rel="noopener noreferrer">
                    Mais informações (whatsapp)
                  </a>
                </div>
              </div>
            </div> */}
            <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={img19} className='card-img-top' alt='Blog 3' />
                <div className='card-body'>
                  <h5 className='card-title'>Interruptor com Dimmer para controle de iluminação - Moes</h5>
                  <p className='card-text'>
                    Controle a intensidade da iluminação dos ambientes. Modelo compatível com os assistentes de
                    voz Amazon Alexa e Google Home, além dos aplicativos Siri, Smart Life e Tuya Smart
                  </p>

                  <a href='
https://api.whatsapp.com/send?phone=5541920042554&text=%20Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto%20que%20vi%20no%20site' onClick={() => gaEventTracker('casa')} className='btn btn-sm btn-primary' target="_blank" rel="noopener noreferrer">
                    Mais informações (whatsapp)
                  </a>
                </div>
              </div>
            </div>
            <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={img20} className='card-img-top' alt='Blog 3' />
                <div className='card-body'>
                  <h5 className='card-title'>Interruptor Inteligente Touch 4 botões - Avatto</h5>
                  <p className='card-text'>
                    Modelo compatível com os assistentes de
                    voz Amazon Alexa e Google Home, além dos aplicativos Siri, Smart Life e Tuya Smart
                    <br /><br />
                  </p>

                  <a href='
https://api.whatsapp.com/send?phone=5541920042554&text=%20Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto%20que%20vi%20no%20site' onClick={() => gaEventTracker('casa')} className='btn btn-sm btn-primary' target="_blank" rel="noopener noreferrer">
                    Mais informações (whatsapp)
                  </a>
                </div>
              </div>
            </div>
            <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={img21} className='card-img-top' alt='Blog 3' />
                <div className='card-body'>
                  <h5 className='card-title'>Interruptor para Ventilador Touch Wifi - MOES</h5>
                  <p className='card-text'>
                    O interruptor permite o controle de ventiladores através de comando de voz, compatível com Amazon Alexa, Google Home e aplicativo Siri, Smart Life, Tuya Smart, entre outros.</p>

                  <a href='
https://api.whatsapp.com/send?phone=5541920042554&text=%20Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto%20que%20vi%20no%20site' onClick={() => gaEventTracker('casa')} className='btn btn-sm btn-primary' target="_blank" rel="noopener noreferrer">
                    Mais informações (whatsapp)
                  </a>
                </div>
              </div>
            </div>
            <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={img22} className='card-img-top' alt='Blog 3' />
                <div className='card-body'>
                  <h5 className='card-title'>Sensor de Temperatura, Umidade e Controle Infravermelho – Smart Home</h5>
                  <p className='card-text'>
                    Com este dispositivo você controla seus ambientes em tempo real, seus equipamentos de TV, Home Theater, Ar Condicionado, entre outros. </p>

                  <a href='
https://api.whatsapp.com/send?phone=5541920042554&text=%20Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto%20que%20vi%20no%20site' onClick={() => gaEventTracker('casa')} className='btn btn-sm btn-primary' target="_blank" rel="noopener noreferrer">
                    Mais informações (whatsapp)
                  </a>
                </div>
              </div>
            </div>
            <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={img23} className='card-img-top' alt='Blog 3' />
                <div className='card-body'>
                  <h5 className='card-title'>Sensor de Temperatura e Umidade a pilha – Tuya</h5>
                  <p className='card-text'>
                    Com este dispositivo você controla seus ambientes em tempo real, seus equipamentos de TV, Home Theater, Ar Condicionado, entre outros.
                    <br /><br />
                  </p>

                  <a href='
https://api.whatsapp.com/send?phone=5541920042554&text=%20Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto%20que%20vi%20no%20site' onClick={() => gaEventTracker('casa')} className='btn btn-sm btn-primary' target="_blank" rel="noopener noreferrer">
                    Mais informações (whatsapp)
                  </a>
                </div>
              </div>
            </div>
            <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={img24} className='card-img-top' alt='Blog 3' />
                <div className='card-body'>
                  <h5 className='card-title'>Controle para Portão Eletrônico</h5>
                  <p className='card-text'>
                    Com esse dispositivo você controlará os acessos de portas, portões e garagens através do APP ou por comando de voz.
                    Compatível com Amazon Alexa, Google Home e outros assistentes virtuais.
                    <br /><br />
                  </p>

                  <a href='
https://api.whatsapp.com/send?phone=5541920042554&text=%20Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto%20que%20vi%20no%20site' onClick={() => gaEventTracker('casa')} className='btn btn-sm btn-primary' target="_blank" rel="noopener noreferrer">
                    Mais informações (whatsapp)
                  </a>
                </div>
              </div>
            </div>
            <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={img25} className='card-img-top' alt='Blog 3' />
                <div className='card-body'>
                  <h5 className='card-title'>Smart Garage - Controlador de portão automático, sensor abre e fecha – Tuya 110/220v</h5>
                  <p className='card-text'>
                    Com esse dispositivo você controlará os acessos de portas, portões e garagens através do APP ou por comando de voz.
                    Compatível com Amazon Alexa, Google Home e outros assistentes virtuais.
                  </p>

                  <a href='
https://api.whatsapp.com/send?phone=5541920042554&text=%20Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto%20que%20vi%20no%20site' onClick={() => gaEventTracker('casa')} className='btn btn-sm btn-primary' target="_blank" rel="noopener noreferrer">
                    Mais informações (whatsapp)
                  </a>
                </div>
              </div>
            </div>
            <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={img26} className='card-img-top' alt='Blog 3' />
                <div className='card-body'>
                  <h5 className='card-title'>Controlador de portão automático, sensor abre e fecha – eWelink – 5v a 24v</h5>
                  <p className='card-text'>
                    Com esse dispositivo você controlará os acessos de portas, portões e garagens através do APP ou por comando de voz.
                    Compatível com Amazon Alexa, Google Home e outros assistentes virtuais.
                  </p>

                  <a href='
https://api.whatsapp.com/send?phone=5541920042554&text=%20Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto%20que%20vi%20no%20site' onClick={() => gaEventTracker('casa')} className='btn btn-sm btn-primary' target="_blank" rel="noopener noreferrer">
                    Mais informações (whatsapp)
                  </a>
                </div>
              </div>
            </div>
            <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={img27} className='card-img-top' alt='Blog 3' />
                <div className='card-body'>
                  <h5 className='card-title'>Tomada de Luxo em Vidro Temperado – Livolo Plug 10A</h5>
                  <p className='card-text'>
                    Tomada com espelho de vidro temperado 5mm, alta qualidade de acabamento e nunca desbota. Cores preta ou branca
                    <br /><br /><br /><br />
                  </p>

                  <a href='
https://api.whatsapp.com/send?phone=5541920042554&text=%20Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto%20que%20vi%20no%20site' onClick={() => gaEventTracker('casa')} className='btn btn-sm btn-primary' target="_blank" rel="noopener noreferrer">
                    Mais informações (whatsapp)
                  </a>
                </div>
              </div>
            </div>
            <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={img28} className='card-img-top' alt='Blog 3' />
                <div className='card-body'>
                  <h5 className='card-title'>Tomada de Luxo em Vidro Temperado – Livolo Plug 20A</h5>
                  <p className='card-text'>
                    Tomada com espelho de vidro temperado 5mm, alta qualidade de acabamento e nunca desbota. Cores preta ou branca</p>

                  <a href='
https://api.whatsapp.com/send?phone=5541920042554&text=%20Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto%20que%20vi%20no%20site' onClick={() => gaEventTracker('casa')} className='btn btn-sm btn-primary' target="_blank" rel="noopener noreferrer">
                    Mais informações (whatsapp)
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Casa;
