import React from 'react';
import useAnalyticsEventTracker from '../../ReactGA/useAnalyticsEventTracker';
import Section from '../../../HOC/Section';

import img1 from '../../../assets/leitores/1.png';
import img2 from '../../../assets/leitores/2.jpg';
import img3 from '../../../assets/leitores/3.png';
import img4 from '../../../assets/leitores/4.png';
import img5 from '../../../assets/leitores/5.png';



const Leitores = () => {
  const gaEventTracker = useAnalyticsEventTracker('Comunicadores');
  return (
    <Section id='leitores'>
      <div className='container pt-2 pb-5'>
        <div className='section-header pt-5 pb-5 text-center'>
          <h3 className='section-title'>
            <span>Leitores </span>de Códigos
          </h3>
          <h6 className='section-subtitle mr-auto ml-auto'>
          Conheça nossos leitores inteligentes: a tecnologia que revoluciona a captura de informações com precisão, agilidade e integração avançada para facilitar a sua rotina.
          </h6>
        </div>
        <div className='section-content'>
          <div className='row'>
            <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={img1} className='card-img-top' alt='Blog 1' />
                <div className='card-body'>
                  <h5 className='card-title'>Mini Leitor De Código De Barras Eyoyo Ey-015 </h5>
                  <p className='card-text'>
                  Leitor de códigos 2D: QR Code, Boletos, DataMatrix e PDF 417. Funcionamento via Bluetooth, cabo e receptor dongle <br /> 
  
                  </p>
                 <a href='https://api.whatsapp.com/send?phone=5541920042554&text=%20Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto%20que%20vi%20no%20site'onClick={()=>gaEventTracker('moto')} className='btn btn-sm btn-primary' target="_blank" rel="noopener noreferrer">
                    Mais informações (whatsapp)
                  </a>
                </div>
              </div>
            </div>
            <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={img2} className='card-img-top' alt='Blog 2' />
                <div className='card-body'>
                  <h5 className='card-title'>Leitor De Código De Barras Eyoyo Ey-017</h5>
                  <p className='card-text'>
                  Leitor acoplável ao celular
Lê códigos 2D: QR Code, Boletos, DataMatrix e PDF 417
Funcionamento via Bluetooth e via cabo 
 <br /> 
</p>
                 <a href='https://api.whatsapp.com/send?phone=5541920042554&text=%20Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto%20que%20vi%20no%20site'onClick={()=>gaEventTracker('moto')} className='btn btn-sm btn-primary' target="_blank" rel="noopener noreferrer">
                  Mais informações (whatsapp)
                  </a>
                </div>
              </div>
            </div>
            <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={img3} className='card-img-top' alt='Blog 3' />
                <div className='card-body'>
                  <h5 className='card-title'>Leitor De Código De Barras Eyoyo Ey-022</h5>
                  <p className='card-text'>
                  Leitor acoplável ao celular
Lê códigos 2D: QR Code, Boletos, DataMatrix e PDF 417
Funcionamento via Bluetooth e via cabo

<br />
</p>
                 <a href='https://api.whatsapp.com/send?phone=5541920042554&text=%20Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto%20que%20vi%20no%20site'onClick={()=>gaEventTracker('moto')} className='btn btn-sm btn-primary' target="_blank" rel="noopener noreferrer">
                  Mais informações (whatsapp)
                  </a>
                </div>
              </div>
            </div>
            <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={img5} className='card-img-top' alt='Blog 3' />
                <div className='card-body'>
                  <h5 className='card-title'>Leitor De Código De Barras Eyoyo Ey-024 </h5>
                  <p className='card-text'>
                  Leitor acoplável ao celular
Leitor de códigos 2D: Qr code , código de barras.
Funcionamento via Cabo, Bluetooth, 2.4g wireless e usb com 3 modos de conexão.

<br />
</p>
                 <a href='https://api.whatsapp.com/send?phone=5541920042554&text=%20Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto%20que%20vi%20no%20site'onClick={()=>gaEventTracker('moto')} className='btn btn-sm btn-primary' target="_blank" rel="noopener noreferrer">
                  Mais informações (whatsapp)
                  </a>
                </div>
              </div>
            </div>
            <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={img4} className='card-img-top' alt='Blog 3' />
                <div className='card-body'>
                  <h5 className='card-title'>Leitor De Código De Barras Eyoyo Ey-023</h5>
                  <p className='card-text'>
                  Leitor de códigos 2D: QR Code, Boletos, DataMatrix e PDF 417.
Funcionamento via Bluetooth, cabo e receptor dongle <br /> <br />

</p>
                 <a href='https://api.whatsapp.com/send?phone=5541920042554&text=%20Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20produto%20que%20vi%20no%20site'onClick={()=>gaEventTracker('moto')} className='btn btn-sm btn-primary' target="_blank" rel="noopener noreferrer">
                  Mais informações (whatsapp)
                  </a>
                </div>
              </div>
            </div>
           </div>
        </div>
      </div>
    </Section>
  );
};

export default Leitores;
