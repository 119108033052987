import React from 'react';

import Link from '../Link/Link';

const footer = () => {
  return (
    <footer className=''>
      <div className='container text-light pt-5'>
        <div className='row'>
          <div className='col-sm-6 col-md-6 col-lg-4 mb-5'>
            <div className='footer-title'>
              <h6>Sobre</h6>
            </div>
            <div className='footer-content'>
              <p>
                <small className='text-muted'>
                A Masters Tecnologia é uma empresa focada na comercialização de produtos eletrônicos para facilitar o seu dia a dia. Possuímos uma gama de produtos que vão deixar a sua casa conectada com você, além de oferecer entrega para todo Brasil.
                </small>
              </p>
              {/* <button className='btn btn-sm btn-primary rounded-0'>
                Learn more
              </button> */}
            </div>
          </div>
          <div className='col-sm-6 col-md-6 col-lg-2 mb-5'>
            <div className='footer-title'>
              <h6>Acessos</h6>
            </div>
            <div className='footer-content'>
              <ul className='list-group quick-links'>
                <li>
                  <Link target='home' offset={-120}>
                    Home
                  </Link>
                </li>
                <li>
                  <Link target='about'>Sobre</Link>
                </li>
                <li>
                  <Link target='services'>Serviços</Link>
                </li>
                <li>
                  <Link target='blog'>Solução</Link>
                </li>
                <li>
                  <Link target='contact'>Contatos</Link>
                </li>
              </ul>
            </div>
          </div>
          {/* <div className='col-sm-6 col-md-6 col-lg-3 mb-5'>
            <div className='footer-title'>
              <h6>Latest News</h6>
            </div>
            <div className='footer-content'>
              <p>
                <small className='text-muted'>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </small>
              </p>
              <p>
                <small className='text-muted'>
                  Pellentesque et pulvinar enim. Quisque at tempor ligula Natus
                  error sit voluptatem
                </small>
              </p>
              <p>
                <small className='text-muted'>accusantium doloremque</small>
              </p>
            </div>
          </div> */}
          <div className='col-sm-6 col-md-6 col-lg-3 mb-5'>
            <div className='footer-title'>
              <h6>Contatos</h6>
            </div>
            <div className='footer-content'>
              <p className='text-muted'>
                <small>Endereço : Rua Presidente Carlos Cavalcanti, 121 - Rio Branco do Sul - PR</small>
              </p>
              <p className='text-muted'>
                <small>Telefone : +55 (41) 92004-2554</small>
              </p>
              <p className='text-muted'>
                <small>E-mail : faleconosco@masterstecnologia.com.br</small>
              </p>
              <div className='social-media mt-4'>
                <a href='https://www.facebook.com/masterstecnologias' className='text-light'>
                  <i className='fab fa-facebook-f mr-4' />
                </a>
                {/* <a href='!#' className='text-light'>
                  <i className='fab fa-twitter mr-4' />
                </a> */}
                <a href='https://www.instagram.com/masters.tecnologias' className='text-light'>
                  <i className='fab fa-instagram mr-4' />
                </a>
                {/* <a href='!#' className='text-light'>
                  <i className='fab fa-github' />
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='bottom-footer pt-3 pb-3 text-center'>
        <small>© All Right Reserved. Desenvolvido por <a href='https://mahann.com.br' target="_blank" alt="Mahann IT Solutions">Mahann IT Solutions</a></small>
      </div>
    </footer>
  );
};

export default footer;
